import { acceptHMRUpdate, defineStore } from 'pinia'

export const useFavoritesStore = defineStore('Favorites', () => {
  const expires = new Date(Date.now() + 30 * 86400 * 1000) // Expires in 30 days
  const favorites = useCookie<string[]>('vehicle-favorites', {
    expires,
  })
  const maxAllowed = 20

  if (!favorites.value || !Array.isArray(favorites.value))
    favorites.value = []

  const isFavorite = computed(() => (vin: string): boolean => favorites.value.includes(vin))
  const count = computed<number>(() => favorites.value.length)

  const add = (vin: string): void => {
    if (!isFavorite.value(vin) && count.value < maxAllowed)
      favorites.value.push(vin)
  }

  const remove = (vin: string): void => {
    if (isFavorite.value(vin))
      favorites.value.splice(favorites.value.indexOf(vin), 1)
  }

  const toggle = (vin: string): void => isFavorite.value(vin) ? remove(vin) : add(vin)

  return {
    favorites,
    add,
    remove,
    toggle,
    count,
    isFavorite,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useFavoritesStore, import.meta.hot))
